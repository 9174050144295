import Vue from "vue"

import { Media } from "@/components/GroupTeams/Common/SmartMedia.vue"
export interface VideoListeners {
  play: Function
  pause: Function
}

export interface ScopedSlotData {
  mute(): void
  unmute(): void
  videoListeners: VideoListeners
}

const WithAudioContext = Vue.extend({
  name: "WithAudioContext",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    slotData(): ScopedSlotData {
      return {
        mute: this.mute,
        unmute: this.unmute,
        videoListeners: {
          play: this.mute,
          pause: this.unmute
        }
      }
    }
  },
  methods: {
    mute() {
      if (this.disabled === false) {
        setTimeout(() => {
          Media.add(this._uid)
        }, 300)
      }
    },
    unmute() {
      if (this.disabled === false) {
        Media.rm(this._uid)
      }
    }
  },
  // TODO: maybe remove
  beforeDestroy() {
    this.unmute()
  },
  render() {
    return this.$scopedSlots.default(this.slotData)
  }
})

export default WithAudioContext
