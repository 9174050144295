import { ref, computed, watch } from "@vue/composition-api"
import Cookies from "js-cookie"

import useSystem from "@/use/useSystem"
import useStore from "@/use/useStore"

const DEFAULT_TIMEOUT = 45 * 1000 * 60 // 45 MINUTES

function isSessionAdSeen(adId: string, userId: string) {
  return Cookies.get(`weve-ad-${adId}-${userId}`) === "true"
}

function setSessionAdAsSeen(adId: string, userId: string) {
  return Cookies.set(`weve-ad-${adId}-${userId}`, "true", { expires: 365 })
}

export default function useLobbyAd() {
  const isOpen = ref(false)

  const { store } = useStore()
  const { bannerMediaUrl: defaultBannerMediaUrl } = useSystem()

  const session = computed(() => store.getters["auth/client"])
  const viewer = computed(() => store.getters["auth/user"])
  const time = computed(() => store.getters["time"])
  const referral = computed(() => session.value?.referral)
  const url = computed(() => referral.value?.url || defaultBannerMediaUrl.value)
  const timestamp = computed(
    () =>
      referral.value?.timestamp ||
      session.value?.startTimestamp + DEFAULT_TIMEOUT
  )

  function hide() {
    isOpen.value = false
  }

  function open() {
    isOpen.value = true
  }

  watch(
    time,
    value => {
      if (value == null) return
      if (timestamp.value == null) return
      if (value < timestamp.value) return
      if (url.value == null) return
      if (referral.value?.disabled) return
      if (isSessionAdSeen(referral.value?.id, viewer.value?.id)) return
      open()
      setSessionAdAsSeen(referral.value?.id, viewer.value?.id)
    },
    { immediate: true }
  )

  return {
    url,
    isOpen,
    open,
    hide
  }
}
